import React from 'react';
import { serverSideTranslation } from '../util/next';

export default function Custom404() {
  return (
    <div
      className="flex flex-col items-center justify-center bg-[#f7f8f9] pb-15 pt-30 text-center text-[24px] leading-[44px] lg:pb-20 lg:pt-40"
      style={{ color: 'rgba(17, 25, 37, 0.6)' }}
    >
      <div className="relative w-[80%] md:w-[60%] lg:w-[533px]">
        <div className="pb-[40.5253283302%]" />
        <img
          alt="404"
          className="absolute inset-0"
          src="https://p0.meituan.net/smartvenus/c35c4245682231c40d17d50e9b1ef62619625.png"
        />
      </div>
      <div className="pt-[48px]">Not Found</div>
    </div>
  );
}

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslation(locale, ['cookie', 'common'])),
    },
  };
}

// Custom404.getLayout = function getLayout(page: ReactElement) {
//   return <>{page}</>;
// };
